<button [ngClass]="buttonClasses()" [disabled]="state().disabled" [class.outlined]="outlined()" (click)="handleClick()">
  @if(state().iconLeft) {
    <ion-icon [name]="state().iconLeft" />
  }
  {{ state().label }}
  @if(state().iconRight) {
    <ion-icon [name]="state().iconRight"></ion-icon>
  }
  @if(textRight()) {
    @if(state().isPrice) {
      <span class="right">{{ textRightDisplay() | currency }}</span>
    } @else {
      <span class="right">{{ textRightDisplay() }}</span>
    }
  }
</button>
