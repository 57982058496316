import { Routes } from '@angular/router';

export const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.routes').then((m) => m.routes),
  },
  {
    path: 'browse',
    loadComponent: () => import('./pages/browse/browse.page').then( m => m.BrowsePage)
  },
  {
    path: 'order/:id',
    loadComponent: () => import('./pages/order/order.page').then( m => m.OrderPage)
  },
  {
    path: 'program/:id',
    loadComponent: () => import('./pages/program/program.page').then( m => m.ProgramPage)
  },
  {
    path: 'account',
    loadComponent: () => import('./pages/account/account.page').then( m => m.AccountPage)
  },
  {
    path: 'set-address',
    loadComponent: () => import('./pages/set-address/set-address.page').then( m => m.SetAddressPage)
  },
  {
    path: 'cart',
    loadComponent: () => import('./pages/cart/cart.page').then( m => m.CartPage)
  },
  {
    path: 'login',
    loadComponent: () => import('./pages/login/login.page').then( m => m.LoginPage)
  },
  {
    path: 'edit-profile',
    loadComponent: () => import('./pages/edit-profile/edit-profile.page').then( m => m.EditProfilePage)
  },
  {
    path: 'medical-profiles',
    loadComponent: () => import('./pages/medical-profiles/medical-profiles.page').then( m => m.MedicalProfilesPage)
  },
  {
    path: 'add-medical-profile',
    loadComponent: () => import('./pages/add-medical-profile/add-medical-profile.page').then( m => m.AddMedicalProfilePage)
  },
  {
    path: 'edit-medical-profile',
    loadComponent: () => import('./pages/edit-medical-profile/edit-medical-profile.page').then( m => m.EditMedicalProfilePage)
  },
  {
    path: 'add-medical-profile-new',
    loadComponent: () => import('./pages/add-medical-profile-new/add-medical-profile-new.page').then( m => m.AddMedicalProfileNewPage)
  },
  {
    path: 'edit-medical-profile-new',
    loadComponent: () => import('./pages/edit-medical-profile-new/edit-medical-profile-new.page').then( m => m.EditMedicalProfileNewPage)
  },
  {
    path: 'payments-coupons',
    loadComponent: () => import('./pages/payments-coupons/payments-coupons.page').then( m => m.PaymentsCouponsPage)
  },
  {
    path: 'payments-list',
    loadComponent: () => import('./pages/payments-list/payments-list.page').then(m => m.PaymentsListPage)
  },
  {
    path: 'coupon-list',
    loadComponent: () => import('./pages/coupon-list/coupon-list.page').then(m => m.CouponListPage)
  },
  {
    path: 'biometrics',
    loadComponent: () => import('./pages/biometrics/biometrics.page').then( m => m.BiometricsPage)
  },
  {
    path: 'device-permissions',
    loadComponent: () => import('./pages/device-permissions/device-permissions.page').then( m => m.DevicePermissionsPage)
  },
  {
    path: 'settings',
    loadComponent: () => import('./pages/settings/settings.page').then( m => m.SettingsPage)
  },
  {
    path: 'medicine-results',
    loadComponent: () => import('./pages/medicine-results/medicine-results.page').then( m => m.MedicineResultsPage)
  },
  {
    path: 'item-cart-edit',
    loadComponent: () => import('./pages/item-cart-edit/item-cart-edit.page').then( m => m.ItemCartEditPage)
  },
  {
    path: 'add-script',
    loadComponent: () => import('./pages/add-script/add-script.page').then( m => m.AddScriptPage)
  },
  {
    path: 'atl',
    loadComponent: () => import('./pages/atl/atl.page').then( m => m.AtlPage)
  },
  {
    path: 'reschedule',
    loadComponent: () => import('./pages/reschedule/reschedule.page').then( m => m.ReschedulePage)
  },
  {
    path: 'click-and-collect',
    loadComponent: () => import('./pages/click-and-collect/click-and-collect.page').then( m => m.ClickAndCollectPage)
  },
  {
    path: 'reset-password',
    loadComponent: () => import('./pages/reset-password/reset-password.page').then( m => m.ResetPasswordPage)
  }
];
