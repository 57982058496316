import { Component, OnInit, computed, inject, input, output, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonContent, IonHeader, IonFooter } from '@ionic/angular/standalone';
import { HeaderComponent } from 'src/app/components/navigation/header/header.component';
import { FooterComponent } from 'src/app/components/navigation/footer/footer.component';
import { ModalController, LoadingController } from '@ionic/angular/standalone';
import { AddressListComponent } from 'src/app/components/elements/address-list/address-list.component';
import { AddressType, PlaceAutocompleteResult, PlaceData } from '@googlemaps/google-maps-services-js';
import { CloudFunctionsService } from 'src/app/services/cloud.functions.service';
import { TitleComponent } from 'src/app/components/primitives/title/title.component';
import { SimpleButtonComponent } from 'src/app/components/primitives/simple-button/simple-button.component';
import { AddressDetailsComponent } from 'src/app/components/elements/address-details/address-details.component';
import { ButtonComponent } from 'src/app/components/primitives/button/button.component';
import { InputComponent } from 'src/app/components/primitives/input/input.component';
import { TSessionAddress } from '@chemist2u/types-client/C2U/Interfaces';
import { StateService } from 'src/app/services/state.service';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { FetchService } from 'src/app/services/fetch.service';
import { CustomerAddress } from '@chemist2u/types-client/C2U/ParseObjects';
import { AddressListViewComponent } from 'src/app/components/scaffolds/address-list/address-list.component';
import { AddressRowComponent } from 'src/app/components/elements/address-row/address-row.component';
import { GeolocationService } from 'src/app/services/geolocation.service';
// import { AmplitudeService } from 'src/app/services/amplitude.service';
import { ErrorService } from 'src/app/services/error.service';
import { StepsContainerComponent } from 'src/app/components/scaffolds/steps-container/steps-container.component';
import C2U from '@chemist2u/types-client';

@Component({
  selector: 'app-select-address',
  templateUrl: './select-address.page.html',
  styleUrls: ['./select-address.page.scss'],
  standalone: true,
  imports: [AddressRowComponent, IonFooter, IonContent, IonHeader, CommonModule, FormsModule, HeaderComponent, FooterComponent, InputComponent, AddressListComponent, TitleComponent, SimpleButtonComponent, AddressDetailsComponent, ButtonComponent, AddressListViewComponent, StepsContainerComponent]
})
export class SelectAddressPage implements OnInit {
  // =============================================================================
  // INJECT SERVICES
  // =============================================================================
  private $geolocation = inject(GeolocationService);
  private $error = inject(ErrorService);
  private $cloud = inject(CloudFunctionsService);
  private $state = inject(StateService);
  private $fetch = inject(FetchService);
  // private $amplitude = inject(AmplitudeService);
  

  // =============================================================================
  // IONIC CONTROLLERS
  // =============================================================================
  private modalController = inject(ModalController);
  private loadingController = inject(LoadingController);


  // =============================================================================
  // INPUTS/OUTPUTS
  // =============================================================================
  public inputAddress = input<TSessionAddress | undefined>(undefined, { alias: 'address' });
  public inputStep = input<number>(0, { alias: 'step' });


  // =============================================================================
  // VARIABLES
  // =============================================================================
  public currentLocation = toSignal(this.$state.bCurrentLocation);

  public address = signal<TSessionAddress | undefined>(undefined);
  public savedAddresses = signal<CustomerAddress[]>([]);
  public currentStep = signal(0);
  public predictions = signal<PlaceAutocompleteResult[]>([]);
  public addressSearchValue: string = '';
  public deliveryNotes: string = '';

  public unitNumber = signal<string>('');
  public unitNumberChanged = (value: string | Date | number) => {
    this.unitNumber.set(value as string);
  };


  steps: string[] = [
    'Search Address',
    'Set Address Details',
  ];

  
  // =============================================================================
  // COMPUTED VARIABLES
  // =============================================================================

  public pageTitle = computed<string>(() => this.steps[this.currentStep()]);

  // get customerAddresses from savedAddresses - TODO - why don't we just use savedAddresses?
  public customerAddresses = computed(() => {
    return this.savedAddresses();
  });

  // isLastStep is a computed variable that returns true if the current step is the last step
  public isLastStep = computed(() => this.currentStep() === this.steps.length - 1);


  // =============================================================================
  // ANGULAR LIFECYCLE
  // =============================================================================
  async ngOnInit() {
    this.$geolocation.getCurrentPosition();
    this.$fetch.customerAdresses().then((addresses) => {
      this.savedAddresses.set(addresses);
    });
  }

  constructor() {
    toObservable(this.inputAddress).subscribe(address => {
      if (!address) {
        address = {
          deliveryToDoor: false,
          deliveryNote: "",
          aptSuiteFloor: "",
          businessName: "",
        } as any;
      }
      this.address.set(address)
    });
    toObservable(this.inputStep).subscribe(step => this.currentStep.set(step));
  }


  // =============================================================================
  // METHODS
  // =============================================================================

  // selectGeoAddress method to select the current location
  async selectGeoAddress() {
    const selectedAddress = this.currentLocation();
    const addressComponents = selectedAddress?.address_components || [];
    const postalCode = addressComponents.find(comp => comp.types.includes(AddressType.postal_code))?.long_name || '';
    // this.$amplitude.track('ADDRESS_SELECTED_GEO', { postalCode });
    const searchValue = selectedAddress?.formatted_address || '';
    if (searchValue.length) {
      const prediction = await this.retrieveFirstPredictionGivenSearchValue(searchValue);
      await this.addressSelected(prediction);
    }
  }

  // selectSavedAddress method to select a saved address from new CustomerAddress table
  async selectSavedAddress(address: CustomerAddress) {
    const addressComponents = address.address.address_components || [];
    const postalCode = addressComponents.find(comp => comp.types.includes(AddressType.postal_code))?.long_name || '';
    // this.$amplitude.track('ADDRESS_SELECTED_RECENT', { postalCode });
    const searchValue = address.address.formatted_address || '';
    const prediction = await this.retrieveFirstPredictionGivenSearchValue(searchValue);
    await this.addressSelected(prediction);
  }

  // searchForAddress method to search for an address given a search value
  // TODO - this has no debounce, so it will make a request for every keypress
  async searchForAddress(searchValue: string | Date | number) {
    // this.$amplitude.track('ADDRESS_SEARCH', { searchTerm: searchValue as string });
    const value = searchValue as string;
    if (value.length < 3) {
      this.predictions.set([]);
      return;
    };

    const result = await this.$cloud.getGooglePlacesPredictions(value);
    const predictions = result.predictions;
    // this.$amplitude.track(predictions.length > 0 ? 'ADDRESS_SEARCH_HAS_RESULTS' : 'ADDRESS_SEARCH_HAS_NO_RESULTS', { searchTerm: searchValue as string });
    this.predictions.set(predictions);
  }

  // retrieveFirstPredictionGivenSearchValue method to retrieve the first prediction given a search value
  async retrieveFirstPredictionGivenSearchValue(searchValue: string) {
    const result = await this.$cloud.getGooglePlacesPredictions(searchValue);
    return result.predictions[0];
  }

  // TODO - this feels really dumb, why not just use the signal or model
  assignDeliveryNotes(value: string | Date | number) {
    this.deliveryNotes = value as string;
    this.address.update(a => (a ? {
      ...a,
      deliveryNote: this.deliveryNotes,
    } : a));
  }

  async searchAddressSelected(prediction: PlaceAutocompleteResult) {
    await this.addressSelected(prediction, true);
  }

  async addressSelected(prediction: PlaceAutocompleteResult, cameFromSearch: boolean = false) {
    // Handle address selection logic here
    const selectedAddress = prediction;
    const placeResult = await this.$cloud.getGooglePlaceDetails(prediction.place_id);
    const placeDetails = placeResult.result as PlaceData;

    const addressComponents = placeDetails.address_components || [];
    const hasStreetNumber = addressComponents.some(comp => comp.types.includes(AddressType.street_number));
    const subpremise = addressComponents.find(comp => comp.types.includes(AddressType.subpremise))?.long_name || '';
    this.unitNumber.set(subpremise);

    if (!hasStreetNumber) {
      this.$error.showToast({
        message: "Please select a valid address with a street number",
        header: "Invalid address",
        position: "bottom",
        duration: 5000,
        swipeGesture: "vertical"
      });
      return;
    }

    if (cameFromSearch) {  
      const postalCode = addressComponents.find(comp => comp.types.includes(AddressType.postal_code))?.long_name || '';
      // this.$amplitude.track('ADDRESS_SELECTED_SEARCH', { postalCode });
    }

    this.address.update(a => (a ? {
      ...a,
      selectedAddress: selectedAddress,
      address: placeDetails,
      aptSuiteFloor: this.unitNumber() || "",
    } : a));

    this.nextStep();
  }

  async navigateToStep(targetStep: number) {
    this.currentStep.set(targetStep);
  }

  async nextStep() {
    const currentStep = this.currentStep();
    const nextStep = currentStep + 1;
    await this.navigateToStep(nextStep);
  }

  async previousStep() {
    if (this.currentStep() === 0) {
      this.dismiss();
      return;
    }
    await this.navigateToStep(this.currentStep() - 1);
  }

  async goToFirstStep() {
    await this.navigateToStep(1);
  }

  async onComplete() {
    await this.setDeliveryNotes();
    await this.dismiss(this.address());
  }

  async setAddress() {
    const addressComponents = this.address()?.address.address_components || [];
    const postalCode = addressComponents.find(comp => comp.types.includes(AddressType.postal_code))?.long_name || '';
    // this.$amplitude.track('ADDRESS_SAVE', { postalCode });
  }

  async setDeliveryNotes() {
    this.address.update(a => ({
      ...a!,
      deliveryNote: this.deliveryNotes,
      aptSuiteFloor: this.unitNumber(),
    }));
    // this.$amplitude.track('ADDRESS_DELIVERY_NOTES', { deliveryNotes: this.deliveryNotes });
  }

  dismiss(data?: TSessionAddress) {
    // this.$amplitude.track('MODAL_DISMISS_SET_ADDRESS');
    this.modalController.dismiss(data);
  }
}