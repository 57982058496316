import { Component, computed, inject, input, output, signal } from '@angular/core';
import { IonIcon } from "@ionic/angular/standalone";
import { addIcons } from 'ionicons';
import { informationCircleOutline, helpCircleOutline, close, warningOutline, warning } from 'ionicons/icons';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-info-bubble',
  templateUrl: './info-bubble.component.html',
  styleUrls: ['./info-bubble.component.scss'],
  standalone: true,
  imports: [IonIcon]
})
export class InfoBubbleComponent {

  private sanitizer = inject(DomSanitizer);

  constructor() {
    addIcons({ informationCircleOutline, helpCircleOutline, close, warningOutline, warning });
  }

  public type = input<'info' | 'help' | 'warning' | 'error'>('info');
  public title = input<string | undefined>();
  public label = input.required<string>();
  public closeable = input<boolean>(false);
  public color = input<'blue' | 'yellow' | 'green' | 'red' | 'promo'>('blue');
  public hasIcon = input<boolean>(true);
  public sanitized = input<boolean>(false);
  public tight = input<boolean>(false);
  public border = input<boolean>(true);
  public visible = signal<boolean>(true);


  public actionEvent = output<void>();

  public iconName = computed(() => {
    switch (this.type()) {
      case "error":
        return "warning";
      case "warning":
        return "warning-outline";
      case "info":
        return "information-circle-outline";
      default:
        return "help-circle-outline";
    }
  });

  public sanitizedHTML = computed(() => {
    if (this.sanitized()) {
      return this.sanitizer.bypassSecurityTrustHtml(this.label());
    }
    return this.label();
  });

  public action() {
    this.actionEvent.emit();
  }

  public close() {
    this.visible.set(false);
  }


}