import { inject, Injectable } from '@angular/core';
import C2U from '@chemist2u/types-client';
import { Parse } from '@chemist2u/types-client/C2U/local-parse';
import {
  PromotionCustomer,
  CardEway,
  CustomerMedicalProfile,
  CustomerMedicalProfile_New,
  CustomerSessionDetails,
  Cart,
  Category,
  Notification,
  Order,
  SubCategory,
  User,
  Item,
  SlideImage,
  Pharmacy,
  Subscriptions,
} from '@chemist2u/types-client/C2U/ParseObjects';
import { CustomerAddress } from '@chemist2u/types-client/C2U/ParseObjects/CustomerAddress';
import { ErrorService } from './error.service';

@Injectable({
  providedIn: 'root'
})
export class FetchService {
  private $error = inject(ErrorService);

  public async slides() {
    return await this.$error.parseErrorWrapper(async () => {
      return (await SlideImage.Query().findAll()).sort((a, b) => a.order - b.order);
    });
  }
  
  public async itemById(itemId: string): Promise<Item | undefined> {
    return await this.$error.parseErrorWrapper(async () => {
      return await Item.getFByID(itemId);
    });
  }

  // Fetch the cart for the current user
  public async cart(): Promise<Cart> {
    return await this.$error.parseErrorWrapper(async () => {
      const cart = await Cart.Query().equalTo('customer', User.current() as User).first();
      return cart || await new Cart().save();
    });
  }

  // Fetch orders for user
  public async orders(): Promise<Order[]> {
    try {
      return await Order.Query().include(['customer', 'items', 'associatedCard']).doesNotExist('deletedAt').descending('createdAt').equalTo('customer', User.current() as User).find();
    } catch (error) {
      return [];
    }
  }
  public async orderById(id: string): Promise<Order | undefined> {
    return await this.$error.parseErrorWrapper(async () => {
		  return await Order.Query().equalTo("objectId", id).equalTo('customer', User.current() as User).include('associatedCard').first();
    });
  }

  public async subscriptions(): Promise<Subscriptions[]> {
    try {
      return await Subscriptions.Query().descending('createdAt').include('cardToUse', 'customer').equalTo('customer', User.current() as User).find();
    } catch (error) {
      return [];
    }
  }

  public async subscriptionById(id: string): Promise<Subscriptions | undefined> {
    return await this.$error.parseErrorWrapper(async () => {
      return await Subscriptions.Query().equalTo("objectId", id).include('cardToUse', 'customer').equalTo('customer', User.current() as User).first();
    });
  }

  public async categories(status: "Active" | "Inactive" = "Active", limit: number = 500): Promise<Category[]> {
    return await this.$error.parseErrorWrapper(async () => {
      return await Category.Query().equalTo("status", status).limit(limit).find();
    });
  }

  public async subCategories(status: "Active" | "Inactive" = "Active", limit: number = 500): Promise<SubCategory[]> {
    return await this.$error.parseErrorWrapper(async () => {
      return await SubCategory.Query().equalTo("status", status).limit(limit).find();
    });
  }

  public async medicalProfiles(): Promise<CustomerMedicalProfile[]> {
    return await this.$error.parseErrorWrapper(async () => {
      return await CustomerMedicalProfile.Query().equalTo("customer", User.current() as User).find();  
    });
  }

  public async medicalProfilesNew(): Promise<CustomerMedicalProfile_New[]> {
    return await this.$error.parseErrorWrapper(async () => {
      return await CustomerMedicalProfile_New.Query().equalTo("customer", User.current() as User).find();  
    });
  }

  public async user(): Promise<User> {
    return await this.$error.parseErrorWrapper(async () => {
      return await User.current()!.fetch() as User;
    });
  }

  // Fetch session for user
  public async session(): Promise<CustomerSessionDetails | undefined> {
    return await this.$error.parseErrorWrapper(async () => {
      return await CustomerSessionDetails.Query().include("customer").descending("updatedAt").equalTo("customer", User.current()).first();
    });
  }

  // Fetch allocated pharmacy for user session
  public async pharmacy(): Promise<Pharmacy | undefined> {
    return await this.$error.parseErrorWrapper(async () => {
      const session = await this.session();
      if (session && session.pharmacy) {
        return await Parse.Cloud.run("getPharmacyDetails", { pharmacyId: session.pharmacy?.pharmacyObjectId});
      }
      return undefined;
    });
  }

  // Fetch cards for user
  public async cards(): Promise<CardEway[]> {
    return await this.$error.parseErrorWrapper(async () => {
      return await CardEway.Query().equalTo("user", User.current() as User).find();
    });
  }

  // Fetch notifications for user
  public async notifications(): Promise<Notification[] | undefined> {
    return await this.$error.parseErrorWrapper(async () => {
      return await Notification.Query().descending("createdAt").equalTo("user", User.current() as User).find();
    });
  }

  public async customerPromotions(): Promise<PromotionCustomer[]> {
    return await this.$error.parseErrorWrapper(async () => {
      const pointer1: C2U.Pointer<'Promotion'> = { __type: "Pointer", className: "Promotion", objectId: "EKTelRRSY5" };
      const pointer2: C2U.Pointer<'Promotion'> = { __type: "Pointer", className: "Promotion", objectId: "3RIH8d1hbF" };
      return await PromotionCustomer.Query().notContainedIn('promotion', [pointer1, pointer2]).find();
    });
  }

  public async customerAdresses(): Promise<CustomerAddress[]> {
    return await this.$error.parseErrorWrapper(async () => {
      return await CustomerAddress.Query().equalTo('customer', User.current() as User).find();
    });
  }

  public async resetPassword(email: string): Promise<any> {
    return await Parse.User.requestPasswordReset(email);
  }
}
