import { computed, inject, Injectable } from '@angular/core';
import { Parse } from '@chemist2u/types-client/C2U/local-parse';
import { CardEway, Category, Cart, CustomerMedicalProfile, CustomerMedicalProfile_New, CustomerSessionDetails, Notification, Order, PromotionCustomer, SubCategory, User, Pharmacy, Subscriptions } from '@chemist2u/types-client/C2U/ParseObjects';
import { BehaviorSubject } from 'rxjs';
import { EventsService } from './events.service';
import { ErrorService } from './error.service';
import { ICart, TOrderFulfillmentMethod, TOrderFulfillmentMethodOnDemand } from '@chemist2u/types-client/C2U/Interfaces';
import { FetchService } from './fetch.service';
import { Position } from '@capacitor/geolocation';
import { GeocodeResult } from '@googlemaps/google-maps-services-js';
import { CloudFunctionsService } from './cloud.functions.service';
import { PushService } from './push.service';
import { Pointer } from '@chemist2u/types-client/C2U/default';
import { toSignal } from '@angular/core/rxjs-interop';
import { TBannerConfig } from '@chemist2u/types-client/C2U/Cloud/banner';

@Injectable({
  providedIn: 'root'
})
export class StateService {
  private $fetch = inject(FetchService);
  private $events = inject(EventsService);
  private $push = inject(PushService);
  private $cloud = inject(CloudFunctionsService);
  private $error = inject(ErrorService);

  public isLoggedIn = computed<boolean>(() => {
    return this.userSignal() != undefined;
  });

  public hasAddress = computed<boolean>(() => {
    return this.isLoggedIn() && this.sessionSignal() != undefined && this.sessionSignal()?.address != undefined;
  });

  constructor() {
    this.bCart.subscribe(cart => {
      console.warn("[StateService]", "bCart", cart);

      if (!cart) return;

      if (!this.originalCart) {
        // This is the first cart emission, so we set the original cart
        this.originalCart = { ...cart.attributes };
      } else {
        if (this.originalCart.pharmacy !== cart.pharmacy && cart.pharmacy) {
          if (this.originalCart.items && cart.items && this.originalCart.items.length !== cart.items.length) {
            this.$error.showToast({
              message: "Your cart has been updated to reflect the selected pharmacy.",
              header: "Info",
              position: "top",
              duration: 2000
            });
          }
        }
        // Update the original cart for the next comparison
        this.originalCart = { ...cart.attributes };
      }
    });

    this.bAvailableFulfillmentMethods.subscribe(async availableMethods => {
      const selectedMethod = this.bSelectedFulfillmentMethod.getValue();
      const selectedMethodName = selectedMethod?.selectedMethod.method;
      const filteredMethods = availableMethods.filter(m => m.selectedMethod.method == selectedMethodName);
      const found = filteredMethods.length > 0;
      const newMethod = found ? filteredMethods[0] : availableMethods[0];
      this.bSelectedFulfillmentMethod.next(newMethod);

      const session = this.bSession.getValue();
      const currFulfillmentMethod = session?.fulfillmentMethod;
      if (session && !currFulfillmentMethod && newMethod) {
        await this.$push.session(session, {
          fulfillmentMethod: newMethod
        });
      }
    });

    this.bSession.subscribe(async session => {
      console.warn("[StateService]", "bSession", session);
      if(!session) return;
      this.updateSessionDetails(session);
    });
  }

  // TODO - URGENT - This is inneficient, we should cache the pharmacyBracketModel
  public async updateSessionDetails(session: CustomerSessionDetails) {
    console.warn("[StateService]", "bSession", session);

    // Let this run before setting the bAvailableFulfillmentMethods
    if (session?.fulfillmentMethod) {
      this.bSelectedFulfillmentMethod.next(session.fulfillmentMethod);
    }

    // Sets bAvailableFulfillmentMethods
    if (session?.address) {
      // TODO - URGENT - This is inneficient, we should cache the pharmacyBracketModel
      const rawOrderFulfillmentMethods = await this.$cloud.getOrderFulfillmentMethodsForPharmacy(session.address);
      const orderFulfillmentMethods = rawOrderFulfillmentMethods.map(method => {
        if (
          session.fulfillmentMethod &&
          session.fulfillmentMethod.selectedMethod.method == "OnDemand" &&
          method.selectedMethod.method == "OnDemand"
        ) {
          const currentShift = (session.fulfillmentMethod as TOrderFulfillmentMethodOnDemand).allocatedShift;
          if (currentShift) {
            const currentShiftExpired = new Date().getTime() > currentShift?.cutoff.getTime();
            if (!currentShiftExpired) {
              return { ...session.fulfillmentMethod };
            }
          }
        }
        return method;
      });
      this.bAvailableFulfillmentMethods.next(orderFulfillmentMethods);
    }

    if (session?.pharmacy && (this.bAllocatedPharmacy.getValue()?.user as User)?.id !== session.pharmacy.id) {
      const pharmacy = await this.$fetch.pharmacy();
      this.bAllocatedPharmacy.next(pharmacy);
    }

    if (session?.pharmacy && !this.bAllocatedPharmacy.getValue()) {
      const pharmacy = await this.$fetch.pharmacy();
      this.bAllocatedPharmacy.next(pharmacy);
    }
  }

  //APP INIT STATES
  public bAuthAppReady = new BehaviorSubject<boolean>(false);

  //DATA VARS
  public bUser = new BehaviorSubject<User | undefined>(undefined);
  public bCart = new BehaviorSubject<Cart | undefined>(undefined);
  public originalCart: ICart | undefined;
  public bCategories = new BehaviorSubject<Category[]>([]);
  public bSubCategories = new BehaviorSubject<SubCategory[]>([]);
  public bSession = new BehaviorSubject<CustomerSessionDetails | undefined>(undefined);
  public bMedicalProfiles = new BehaviorSubject<CustomerMedicalProfile[]>([]);
  public bMedicalProfilesNew = new BehaviorSubject<CustomerMedicalProfile_New[]>([]);
  public bCards = new BehaviorSubject<CardEway[]>([]);
  public bPromotionCustomer = new BehaviorSubject<PromotionCustomer[]>([]);
  public bOrders = new BehaviorSubject<Order[]>([]);
  public bSubscriptions = new BehaviorSubject<Subscriptions[]>([]);
  public bNotification = new BehaviorSubject<Notification[]>([]);
  public bAllocatedPharmacy = new BehaviorSubject<Pharmacy | undefined>(undefined);
  public bAvailableFulfillmentMethods = new BehaviorSubject<TOrderFulfillmentMethod[]>([]);
  public bSelectedFulfillmentMethod = new BehaviorSubject<TOrderFulfillmentMethod | undefined>(undefined);
  public bInconvenienceMessage = new BehaviorSubject<TBannerConfig>({ message: "", show: false });
  public bDismissedInconvenienceMessage = new BehaviorSubject<Date | undefined>(undefined);

  // Internal signals
  private userSignal = toSignal(this.bUser);
  private sessionSignal = toSignal(this.bSession);

  //NON LIVEQUERY DATA
  public bGeoLocation = new BehaviorSubject<Position | undefined>(undefined);
  public bCurrentLocation = new BehaviorSubject<GeocodeResult | undefined>(undefined);


  //BEHAVIORAL SUBJECTS FOR LIVE QUERY
  public bCardEwaySub = new BehaviorSubject<Parse.LiveQuerySubscription | null>(null);
  public bCustomerSessionDetailsSub = new BehaviorSubject<Parse.LiveQuerySubscription | null>(null);
  public bCustomerMedicalProfilesSub = new BehaviorSubject<Parse.LiveQuerySubscription | null>(null);
  public bCustomerMedicalProfilesNewSub = new BehaviorSubject<Parse.LiveQuerySubscription | null>(null);
  public bPromotionCustomerSub = new BehaviorSubject<Parse.LiveQuerySubscription | null>(null);
  public bOrderSub = new BehaviorSubject<Parse.LiveQuerySubscription | null>(null);
  public bSubscriptionsSub = new BehaviorSubject<Parse.LiveQuerySubscription | null>(null);
  public bUserSub = new BehaviorSubject<Parse.LiveQuerySubscription | null>(null);
  public bCartSub = new BehaviorSubject<Parse.LiveQuerySubscription | null>(null);
  public bNotificationSub = new BehaviorSubject<Parse.LiveQuerySubscription | null>(null);

  public bCardEwaySubConnected = new BehaviorSubject<boolean>(false);
  public bCustomerSessionDetailsSubConnected = new BehaviorSubject<boolean>(false);
  public bCustomerMedicalProfilesSubConnected = new BehaviorSubject<boolean>(false);
  public bCustomerMedicalProfilesNewSubConnected = new BehaviorSubject<boolean>(false);
  public bPromotionCustomerSubConnected = new BehaviorSubject<boolean>(false);
  public bOrderSubConnected = new BehaviorSubject<boolean>(false);
  public bSubscriptionsSubConnected = new BehaviorSubject<boolean>(false);
  public bUserSubConnected = new BehaviorSubject<boolean>(false);
  public bCartSubConnected = new BehaviorSubject<boolean>(false);
  public bNotificationSubConnected = new BehaviorSubject<boolean>(false);

  private liveQueryInitialized: boolean = false;
  private lqListenersInitialized: boolean = false;

  killLiveQuery() {
    const subscriptions = [
      this.bCardEwaySub.getValue(),
      this.bCustomerSessionDetailsSub.getValue(),
      this.bCustomerMedicalProfilesSub.getValue(),
      this.bCustomerMedicalProfilesNewSub.getValue(),
      this.bPromotionCustomerSub.getValue(),
      this.bOrderSub.getValue(),
      this.bSubscriptionsSub.getValue(),
      this.bUserSub.getValue(),
      this.bCartSub.getValue(),
      this.bNotificationSub.getValue(),
    ];

    subscriptions.forEach(sub => {
      if (sub) {
        sub.unsubscribe();
      }
    });
    this.liveQueryInitialized = false;
  }

  async initialiseLiveQuery() {
    const user = Parse.User.current();

    if (this.liveQueryInitialized === true) return
    // CREATE ALL THE SUBSCRIBERS
    if (user) {
      const userPointer = user as any as Pointer<"_User">;
      this.bUserSub.next(await new Parse.Query(Parse.User).equalTo('objectId', user.id).subscribe());
      this.bCardEwaySub.next(await new Parse.Query(CardEway).subscribe());
      this.bCustomerSessionDetailsSub.next(await new Parse.Query(CustomerSessionDetails).subscribe());
      this.bCustomerMedicalProfilesSub.next(await new Parse.Query(CustomerMedicalProfile).equalTo('customer', userPointer).subscribe());
      // this.bCustomerMedicalProfilesNewSub.next(await new Parse.Query(CustomerMedicalProfile_New).equalTo('customer', userPointer).subscribe());
      this.bPromotionCustomerSub.next(await new Parse.Query(PromotionCustomer).subscribe());
      this.bCartSub.next(await new Parse.Query(Cart).equalTo('customer', userPointer).subscribe());
      this.bOrderSub.next(await new Parse.Query(Order).equalTo('customer', userPointer).subscribe());
      this.bSubscriptionsSub.next(await Subscriptions.Query().equalTo('customer', userPointer).include('cardToUse', 'customer').subscribe());
      // this.bNotificationSub.next(await new Parse.Query(Notification).equalTo('user', userPointer).subscribe());
    }

    if (this.lqListenersInitialized) return;
    this.lqListenersInitialized = true;

    // SETUP THE BEHAVIORAL SUBJECTS
    // CARDEWAY SETUP
    this.bCardEwaySub.subscribe(value => {
      if (value) this.setupArraySubscription<CardEway>(value, this.bCardEwaySubConnected, this.bCards, 'CardEway');
    });

    // CART SETUP
    this.bCartSub.subscribe(value => {
      if (value) this.setupSingletonSubscription<Cart>(value, this.bCartSubConnected, this.bCart, 'Cart');
    });

    // MEDICAL PROFILES SETUP
    this.bCustomerSessionDetailsSub.subscribe(value => {
      if (value) this.setupSingletonSubscription<CustomerSessionDetails>(value, this.bCustomerSessionDetailsSubConnected, this.bSession, 'CustomerSessionDetails');
    });

    // PROMOTION CUSTOMER SETUP
    this.bPromotionCustomerSub.subscribe(value => {
      if (value) this.setupArraySubscription<PromotionCustomer>(value, this.bPromotionCustomerSubConnected, this.bPromotionCustomer, 'PromotionCustomer');
    });

    // CUSTOMER SESSION DETAILS
    this.bCustomerMedicalProfilesSub.subscribe(value => {
      if (value) this.setupArraySubscription<CustomerMedicalProfile>(value, this.bCustomerMedicalProfilesSubConnected, this.bMedicalProfiles, 'CustomerMedicalProfile');
    });

    // CUSTOMER SESSION DETAILS
    this.bCustomerMedicalProfilesNewSub.subscribe(value => {
      if (value) this.setupArraySubscription<CustomerMedicalProfile_New>(value, this.bCustomerMedicalProfilesNewSubConnected, this.bMedicalProfilesNew, 'CustomerMedicalProfile_New');
    });

    // ORDER SETUP
    this.bOrderSub.subscribe(value => {
      if (value) this.setupArraySubscription<Order>(value, this.bOrderSubConnected, this.bOrders, 'Order');
    });

    // SUBSCRIPTIONS SETUP
    this.bSubscriptionsSub.subscribe(value => {
      if (value) this.setupArraySubscription<Subscriptions>(value, this.bSubscriptionsSubConnected, this.bSubscriptions, 'Subscriptions');
    });

    // NOTIFICATION SETUP
    this.bNotificationSub.subscribe(value => {
      if (value) this.setupArraySubscription<Notification>(value, this.bNotificationSubConnected, this.bNotification, 'Notification');
    });
    this.liveQueryInitialized = true;
  }

  populateState(states: {
    categories: Category[],
    subCategories: SubCategory[],
    orders: Order[],
    subscriptions: Subscriptions[],
    medicalProfiles: CustomerMedicalProfile[],
    medicalProfilesNew: CustomerMedicalProfile_New[],
    user: User,
    session: CustomerSessionDetails | undefined,
    pharmacy: Pharmacy | undefined,
    cart: Cart,
    cards: CardEway[],
    promotions: PromotionCustomer[]
  }) {
    console.warn("[StateService]", "Populating State");
    this.bCategories.next(states.categories);
    this.bSubCategories.next(states.subCategories);
    this.bOrders.next(states.orders);
    this.bSubscriptions.next(states.subscriptions);
    this.bMedicalProfiles.next(states.medicalProfiles);
    this.bMedicalProfilesNew.next(states.medicalProfilesNew);
    this.bUser.next(states.user);
    this.bSession.next(states.session);
    this.bAllocatedPharmacy.next(states.pharmacy);
    this.bCart.next(states.cart);
    this.bCards.next(states.cards);
    this.bAuthAppReady.next(true);
    this.bPromotionCustomer.next(states.promotions);
    setTimeout(() => {
      this.$events.triggerAuthChangeComplete.next();
    });
  }

  clearState() {
    console.warn("[StateService]", "Clearing State");
    this.bOrders.next([]);
    this.bSubscriptions.next([]);
    this.bMedicalProfiles.next([]);
    this.bMedicalProfilesNew.next([]);
    this.bUser.next(undefined);
    this.bSession.next(undefined);
    this.bAllocatedPharmacy.next(undefined);
    this.bCart.next(undefined);
    this.bCards.next([]);
    this.bNotification.next([]);
    this.bPromotionCustomer.next([]);
    this.bAuthAppReady.next(false);
    this.bAvailableFulfillmentMethods.next([]);
    this.bSelectedFulfillmentMethod.next(undefined);
    setTimeout(() => {
      this.$events.triggerAuthChangeComplete.next();
    });
  }

  private setupArraySubscription<T extends Parse.Object<Parse.Attributes>>(
    subscription: Parse.LiveQuerySubscription,
    connectionSubject: BehaviorSubject<boolean>,
    subject: BehaviorSubject<T[]>,
    itemType: string
  ) {
    // Startup logging
    subscription.on('open', () => {
      console.log(`[StateService] (OPEN) ${itemType} LiveQuery`);
      connectionSubject.next(true);
    });
    subscription.on('close', () => {
      console.log(`[StateService] (CLOSE) ${itemType} LiveQuery`);
      connectionSubject.next(false);
    });

    // add item to list
    const addItem = (item: Parse.Object<Parse.Attributes>) => {
      console.log(`[StateService] (ADD ITEM) ${itemType} LiveQuery`, item);
      
      // Retrieve the current array of items
      const currentItems = subject.getValue();
      
      // Find the index of the existing item with the same id
      const index = currentItems.findIndex(existingItem => existingItem.id === item.id);
      
      if (index !== -1) {
        // Item exists, update it
        const updatedItems = [...currentItems];
        updatedItems[index] = item as T;
        subject.next(updatedItems);
      } else {
        // Item does not exist, add it
        const updatedItems = [...currentItems, item as T];
        subject.next(updatedItems);
      }
    };

    // tried to use immutability
    const updateItem = (item: Parse.Object<Parse.Attributes>) => {
      console.log(`[StateService] (UPDATE ITEM) ${itemType} LiveQuery`, item);
      const items = subject.getValue();
      const index = items.findIndex(i => i.id === item.id);
      if (index >= 0) {
        const updatedItems = [
          ...items.slice(0, index),
          item as T,
          ...items.slice(index + 1)
        ];
        subject.next(updatedItems);
      }
    };

    // remove item from list
    const removeItem = (item: Parse.Object<Parse.Attributes>) => {
      const updatedItems = subject.getValue().filter(i => i.id !== item.id);
      subject.next(updatedItems);
    };

    // not sure if all are actually needed, especially enter and leave
    subscription.on('create', addItem);
    subscription.on('enter', addItem);
    subscription.on('update', updateItem);
    subscription.on('delete', removeItem);
    subscription.on('leave', removeItem);
  }

  private setupSingletonSubscription<T extends Parse.Object<Parse.Attributes>>(
    subscription: Parse.LiveQuerySubscription,
    connectionSubject: BehaviorSubject<boolean>,
    subject: BehaviorSubject<T | undefined>,
    itemType: string
  ) {
    // Startup logging
    subscription.on('open', () => {
      console.log(`[StateService] (OPEN) ${itemType} LiveQuery`);
      connectionSubject.next(true);
    });
    subscription.on('close', () => {
      console.log(`[StateService] (CLOSE) ${itemType} LiveQuery`);
      connectionSubject.next(false);
    });

    // not sure if all events are needed enter and leave dicey
    subscription.on('create', (item: Parse.Object<Parse.Attributes>) => subject.next(item as T));
    subscription.on('update', (item: Parse.Object<Parse.Attributes>) => {
      console.log(`[StateService] (UPDATE) ${itemType} LiveQuery`, item);
      return subject.next(item as T)
    });
    subscription.on('delete', () => subject.next(undefined));
    subscription.on('enter', (item: Parse.Object<Parse.Attributes>) => subject.next(item as T));
    subscription.on('leave', () => subject.next(undefined));
  }

  public initialiseSetupData() {
    Promise.all([
      this.$fetch.categories(),
      this.$fetch.subCategories(),
    ]).then(value => {
      const [categories, subCategories] = value;
      this.bCategories.next(categories);
      this.bSubCategories.next(subCategories);
    });
  }

  public initialiseAuthSetupData() {
    Promise.all([
      this.$fetch.categories(),
      this.$fetch.subCategories(),
      this.$fetch.orders(),
      this.$fetch.subscriptions(),
      this.$fetch.medicalProfiles(),
      this.$fetch.medicalProfilesNew(),
      this.$fetch.user(),
      this.$fetch.session(),
      this.$fetch.pharmacy(),
      this.$fetch.cart(),
      this.$fetch.cards(),
      this.$fetch.customerPromotions()
    ]).then(value => {
      const [categories, subCategories, orders, subscriptions, medicalProfiles, medicalProfilesNew, user, session, pharmacy, cart, cards, promotions] = value;
      this.populateState({
        categories, subCategories, orders, subscriptions, medicalProfiles, medicalProfilesNew, user, session, pharmacy, cart, cards, promotions
      });
    });

    // Notifications - not required for app init
    this.$fetch.notifications().then(notifications => {
      this.bNotification.next(notifications || []);
    });
  }

}
