import { Component, computed, inject, input, OnInit, output, signal } from '@angular/core';
import { PaymentRowComponent } from '../payment-row/payment-row.component';
import { StateService } from 'src/app/services/state.service';
import { toSignal } from '@angular/core/rxjs-interop';
import { environment } from 'src/environments/environment';
import { SimpleButtonComponent } from '../../primitives/simple-button/simple-button.component';
import { LoadingController, ModalController } from '@ionic/angular/standalone';
import { CloudFunctionsService } from 'src/app/services/cloud.functions.service';
import { ErrorService } from 'src/app/services/error.service';
import { ICardEway } from '@chemist2u/types-client/C2U/Interfaces';
import { CardEway } from '@chemist2u/types-client/C2U/ParseObjects';
import { PaymentsListPage } from 'src/app/pages/payments-list/payments-list.page';
import { PushService } from 'src/app/services/push.service';
import { AmplitudeService } from 'src/app/services/amplitude.service';

declare var Square: any;

@Component({
  selector: 'app-payment-wrapper',
  templateUrl: './payment-wrapper.component.html',
  styleUrls: ['./payment-wrapper.component.scss'],
  imports: [PaymentRowComponent, SimpleButtonComponent],
  standalone: true,
})
export class PaymentWrapperComponent implements OnInit {
  private loadingController = inject(LoadingController);
  private modalController = inject(ModalController);
  private $push = inject(PushService);
  private $error = inject(ErrorService);

  private $amplitude = inject(AmplitudeService);
  private $state = inject(StateService);
  private $cloud = inject(CloudFunctionsService);

  // cardStyles
  public cardStyles = {
    '.input-container': {
      borderColor: '#97A6BD',
      borderRadius: '6px',
    },
    '.input-container.is-focus': {
      borderColor: '#4E6887',
    },
    '.input-container.is-error': {
      borderColor: '#FF1675',
    },
    '.message-text': {
      color: '#97A6BD',
    },
    '.message-icon': {
      color: '#97A6BD',
    },
    '.message-text.is-error': {
      color: '#FF1675',
    },
    '.message-icon.is-error': {
      color: '#FF1675',
    },
    input: {
      backgroundColor: '#FFFFFF',
      color: '#003E63',
      fontFamily: 'helvetica neue, sans-serif',
    },
    'input::placeholder': {
      color: '#D2D8E2',
    },
    'input.is-error': {
      color: '#ff1600',
    },
  };

  public pushToCart = input<boolean>(false);
  public selectedCard = input<CardEway | undefined>(undefined);
  public cardChange = output<CardEway>();

  private user = toSignal(this.$state.bUser);
  private card = signal<any>(undefined);

  public cards = toSignal(this.$state.bCards, { requireSync: true });
  public isLoading = signal<boolean>(true);
  public cardToUse = signal<CardEway | undefined>(undefined);

  async ngOnInit() {
    const payments = Square.payments(environment.sqAppID, environment.sqLocationID);

    const res = await payments.card({ style: this.cardStyles });
    this.card.set(res);
    await this.card().attach('#paymentWrapper-cardContainer');
    this.isLoading.set(false);

    this.cardToUse.set(this.setCardToUse());
  }

  private setCardToUse() {
    if (this.selectedCard()) {
      return this.selectedCard();
    }
    if (!this.cards()) {
      return undefined;
    }
    if (this.cards()!.length === 0) {
      return undefined;
    }
    if (this.cards()!.length === 1) {
      return this.cards()![0];
    }
    return this.cards()!.sort((a, b) => a.updatedAt ? -1 : 1)[0];
  }

  async changeCard() {
    const modal = await this.modalController.create({
      component: PaymentsListPage,
      canDismiss: true,
      animated: true,
      showBackdrop: true,
      presentingElement:  await this.modalController.getTop(),
      componentProps: {
        pageState: "modal",
        canSelect: true,
      }
    });
    await modal.present();
    this.$amplitude.track('MODAL_SHOW_WALLET');
    const { data } = await modal.onWillDismiss();
    this.$amplitude.track('MODAL_DISMISS_WALLET');
    if (data) {
      this.cardToUse.set(data);
      this.cardChange.emit(data);
    }
    // if (modal) {
    //   modal.dismiss();
    // }
    // this.router.navigate(['/payments-list']);
  }


  async addCard() {
    const user = this.user();
    if (user == undefined) return;
    const loader = await this.loadingController.create();
    try {
      await loader.present();
      this.isLoading.set(true);
      const result = await this.card().tokenize();
      if (result.status === 'OK') {
        const cardResult = await this.$cloud.createSquareCardToken({
          nonce: result.token,
          customerid: user.id,
        });

        if (cardResult == undefined) {
          throw Error();
        }

        const cardObj: ICardEway = {
          expMonth: cardResult.card.expMonth,
          expYear: cardResult.card.expYear,
          brand: cardResult.card.cardBrand,
          last4: cardResult.card.last4,
          squareToken: cardResult.card.id,
          user: user
        };
        
        const card = await this.$push.newCard(cardObj);
        this.cardToUse.set(card);
        this.cardChange.emit(card);

        loader.dismiss();
      }
      this.isLoading.set(false);
    } catch (e: any) {
      loader.dismiss();
      console.error(e);
      this.isLoading.set(false);
      this.$error.showToast({
        message: "Error adding new card",
        header: "Error",
        position: "top",
        duration: 2000,
      });
      this.$error.handleParseError(e);
    } finally {
      this.isLoading.set(false);
      loader.dismiss();
    }
  }
}