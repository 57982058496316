import { Component, computed, inject, OnInit, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonContent, IonHeader, IonFooter } from '@ionic/angular/standalone';
import { HeaderComponent } from 'src/app/components/navigation/header/header.component';
import { SimpleButtonComponent } from 'src/app/components/primitives/simple-button/simple-button.component';
import { FooterComponent } from 'src/app/components/navigation/footer/footer.component';
import { ButtonComponent } from 'src/app/components/primitives/button/button.component';
import { SectionDescriptionComponent } from 'src/app/components/elements/section-description/section-description.component';
import { InputComponent } from 'src/app/components/primitives/input/input.component';
import { LoginWrapperComponent } from 'src/app/components/elements/login-wrapper/login-wrapper.component';
import { LabelComponent } from 'src/app/components/primitives/label/label.component';
import { ModalController, LoadingController } from '@ionic/angular/standalone';
import { TooltipService } from 'src/app/services/tooltip.service';
import { OtpComponent } from 'src/app/components/primitives/otp/otp.component';
import { CloudFunctionsService } from 'src/app/services/cloud.functions.service';
import { ErrorService } from 'src/app/services/error.service';
import { AuthService } from 'src/app/services/auth.service';
import { addIcons } from 'ionicons';
import { refreshCircleOutline } from 'ionicons/icons';
import { environment } from 'src/environments/environment';
import { PasswordRulesComponent } from 'src/app/components/elements/password-rules/password-rules.component';
import { AmplitudeService } from 'src/app/services/amplitude.service';
import { ResetPasswordPage } from '../reset-password/reset-password.page';
import { StepsContainerComponent } from 'src/app/components/scaffolds/steps-container/steps-container.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
  standalone: true,
  imports: [IonFooter, ButtonComponent, FooterComponent, IonContent, IonHeader, CommonModule, FormsModule, HeaderComponent, SimpleButtonComponent, SectionDescriptionComponent, InputComponent, LoginWrapperComponent, LabelComponent, OtpComponent, PasswordRulesComponent, StepsContainerComponent]
})
export class LoginPage implements OnInit {
  private modalController = inject(ModalController);
  private tooltipService = inject(TooltipService);
  private loadingController = inject(LoadingController);
  private $cloud = inject(CloudFunctionsService);
  private $error = inject(ErrorService);
  private $auth = inject(AuthService);
  private $amplitude = inject(AmplitudeService);

  public mobileNumber = signal('');
  public mobileNumberError = signal<string | undefined>(undefined);
  public password = signal('');
  public otp = signal('');
  public otpValidated = signal(false);
  public loader: HTMLIonLoadingElement | undefined;

  public firstName = signal<string>('');
  public lastName = signal<string>('');
  public email = signal<string>('');

  public currentStep = signal(0);
  public processingStep = signal<boolean>(false);
  steps: string[] = [
    'mobile',
    'pin',
    'signup',
    'password',
    // Add or remove steps as needed
  ];
  isLastStep = computed(() => this.currentStep() === this.steps.length - 1);

  onMobileNumberChange(mobile: any) {
    if (mobile.length > 0) {
      this.mobileNumber.set(mobile);
      const validMobile = this.validateMobileNumber(mobile);
      this.mobileNumberError.set(validMobile ? undefined : 'Invalid mobile number');
    } else {
      this.mobileNumberError.set(undefined);
      this.mobileNumber.set(mobile);
    }
  }

  onPasswordValidation(valid: boolean) {
    this.signupPasswordValid.set(valid);
  }

  signupPasswordValid = signal<boolean>(false);

  onPasswordChange(password: any) {
    this.password.set(password);
  }

  onFirstNameChange(firstName: any) {
    this.firstName.set(firstName);
  }

  onLastNameChange(lastName: any) {
    this.lastName.set(lastName);
  }

  onEmailChange(email: any) {
    this.email.set(email);
  }

  nameValid = computed<boolean>(() => {
    return this.firstName().length > 0 && this.lastName().length > 0;
  });

  emailValid = computed<boolean>(() => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.email());
  });

  mobileNumberValid = computed(() => !this.mobileNumberError() && this.mobileNumber().length > 0);
  otpValid = computed(() => this.otp().length === 6);
  signupValid = computed(() => this.nameValid() && this.emailValid() && this.signupPasswordValid());
  loginPasswordValid = computed(() => this.password().length > 6);

  validateMobileNumber(mobile: string) {
    const AUSTRALIAN_MOBILE_REGEX = /^(?:\+61|0)[4-5]\d{8}$/;
    const valid = AUSTRALIAN_MOBILE_REGEX.test(mobile);
    return valid;
  }

  sendCodeDisabled = signal<boolean>(false);
  async sendCode() {
    if (this.sendCodeDisabled() || !this.mobileNumberValid()) return;
    this.sendCodeDisabled.set(true);
    await this.$cloud.authyNewUser(this.mobileNumber());
    setTimeout(() => {
      this.sendCodeDisabled.set(false);
    }, 3000);
  }

  async nextStep() {
    if (this.processingStep()) return;
    this.processingStep.set(true);
    const currentStep = this.currentStep();
    let nextStep = currentStep + 1;

    if (this.steps[currentStep] === "mobile") {
      try {
        this.$amplitude.track('AUTH_LOGIN_PHONE_ENTERED');
        await this.loader?.present();
        if (environment.production) {
          await this.sendCode();
        }
        this.$amplitude.track('OTP_SEND_SUCCESS');
        await this.loader?.dismiss();
      } catch (err: unknown) {
        await this.loader?.dismiss();
        console.error(err);
        this.$error.showToast({ message: 'Error sending OTP', duration: 5000 });
      }
    }

    pinCheck: if (this.steps[currentStep] === "pin") {
      try {
        // TODO: Remove this?
        if (this.otp() == "123456") break pinCheck;
        this.loader = await this.loadingController.create();
        await this.loader?.present();
        const verifyResult = await this.$cloud.authyVerify(this.otp(), this.mobileNumber());
        if (verifyResult === "approved") {
          this.otpValidated.set(true);
          this.$amplitude.track('OTP_VERIFY_SUCCESS');
        } else {
          this.otpValidated.set(false);
          this.$amplitude.track('OTP_VERIFY_FAIL');
        }
        if (!this.otpValidated()) {
          await this.loader?.dismiss();
          this.$error.showToast({ message: 'Error verifying OTP', duration: 5000, position: "top", color: "danger" });
          return this.processingStep.set(false);
        } else {
          await this.loader?.dismiss();
        }
      } catch(error) {
        await this.loader?.dismiss();
        console.error(error);
        this.$error.showToast({ message: 'Error verifying OTP', duration: 5000, position: "top", color: "danger" });
        return this.processingStep.set(false);
      }
      
    }

    // Skip to password if user exists
    if (this.steps[currentStep] == "pin") {
      const usernameExists = await this.$cloud.doesUserExist(this.mobileNumber());
      this.$amplitude.track(usernameExists ? 'AUTH_LOGIN_USER_FOUND' : 'AUTH_LOGIN_USER_NOT_FOUND');
      if (usernameExists) nextStep++;
    }

    if (this.steps[currentStep] === "signup") {
      this.loader = await this.loadingController.create();
      await this.loader?.present();
      try {
        const emailExists = await this.$cloud.doesUserEmailExist(this.email());
        if (emailExists) throw new Error();
        await this.$auth.signUp(this.email(), this.mobileNumber(), this.password(), this.firstName(), this.lastName());
        await this.loader?.dismiss();
        await this.dismiss();
      } catch (err: unknown) {
        await this.loader?.dismiss();
        console.error(err);
        this.$error.showToast({ message: 'Error email already exists', duration: 5000 });
        return this.processingStep.set(false);
      }
    }

    if (this.steps[currentStep] === "password") {
      this.loader = await this.loadingController.create();
      await this.loader?.present();
      try {
        await this.$auth.logIn(this.mobileNumber(), this.password());
        await this.loader?.dismiss();
        await this.dismiss();
      } catch (err: any) {
        await this.loader?.dismiss();
        let errMessage = 'Error logging in';
        if (err.code == "biometric_fail") {
          errMessage = err.message;
        } else if (err.code == 101 && err.message == "Invalid username/password.") {
          errMessage = "Invalid phone number/password.";
        }
        this.$error.showToast({ message: errMessage, duration: 5000 });
        return this.processingStep.set(false);
      }
    }

    this.navigateToStep(nextStep);
    return this.processingStep.set(false);
  }

  async resetPassword() {
    const resetPasswordModal = await this.modalController.create({
      component: ResetPasswordPage,
      canDismiss: true,
      animated: true,
      showBackdrop: true,
      presentingElement: await this.modalController.getTop() ? await this.modalController.getTop() : document.querySelector('ion-router-outlet')!,
    });

    await resetPasswordModal.present();
  }

  navigateToStep(step: number) {
    this.currentStep.set(step);
  }

  async previousStep() {
    const currentStep = this.currentStep();
    if (this.currentStep() === 0) {
      this.dismiss();
      return;
    }
    let previousStep = currentStep - 1;
    if (this.steps[currentStep] == 'password') {
      previousStep--;
    }
    this.navigateToStep(previousStep);
  }

  async goToFirstStep() {
    this.navigateToStep(1);
  }

  showTooltip(event: MouseEvent) {
    this.tooltipService.show({ label: 'Only available with Australian phone numbers', x: event.clientX, y: event.clientY });
  }


  constructor() {
    addIcons({ refreshCircleOutline });
  }

  async ngOnInit() {
    this.loader = await this.loadingController.create();
  }

  async dismiss() {
    if (await this.modalController.getTop()) {
      await this.modalController.dismiss();
      this.$amplitude.track('MODAL_DISMISS', { modal: 'login' });
    }
  }

  async checkOTPCode(value: string) {
    if (value.length === 6) {
      this.otp.set(value);
      await this.nextStep();
    }
  }

}
