import { inject, Injectable } from '@angular/core';
import C2U from "@chemist2u/types-client";
import { TCreateSquareCardTokenForCustomer } from '@chemist2u/types-client/C2U/Cloud/cardeway';
import { TLoadItemSingle } from '@chemist2u/types-client/C2U/Cloud/item';
import { TUpdateCustomerMedicalProfile } from '@chemist2u/types-client/C2U/Cloud/medicalprofile';
import { IPharmacy, TAllocatedShift, TCartItem, TOrderFulfillmentMethod, TSessionAddress } from '@chemist2u/types-client/C2U/Interfaces';
import { Order, PromotionCustomer } from '@chemist2u/types-client/C2U/ParseObjects';
import { TConciergeDeliveryString } from '@chemist2u/types-client/C2U/Serviceability/default';
import { TCardResult } from '@chemist2u/types-client/C2U/Square/default';
import { Parse } from '@chemist2u/types-client/C2U/local-parse';
import { ErrorService } from './error.service';
import { TBannerConfig } from '@chemist2u/types-client/C2U/Cloud/banner';

@Injectable({
  providedIn: 'root'
})
export class CloudFunctionsService {
  private $error = inject(ErrorService);

  public async getAppConfig() {
    return await this.$error.parseErrorWrapper(async () => {
      return await C2U.Cloud.run('getAppConfig', {});
    });
  }

  //Function takes a searchString and returns a list of predictions from Google Places API
  public async getGooglePlacesPredictions(search: string) {
    return await this.$error.parseErrorWrapper(async () => {
      return await C2U.Cloud.run("googlePlacesPredictions", {
        searchString: search,
      })
    });
  }

  //Function takes a placeId and returns the details of that place from Google Places API
  public async getGooglePlaceDetails(placeId: string) {
    return await this.$error.parseErrorWrapper(async () => {
      return await C2U.Cloud.run("googlePlaceDetails", { placeId });
    });
  }

  //Authy functions
  public async authyNewUser(newCellPhone: string) {
    return await this.$error.parseErrorWrapper(async () => {
      return await C2U.Cloud.run("authyNewUser", { newCellPhone });
    });
  }

  public async authyVerify(code: string, newCellPhone: string) {
    return await this.$error.parseErrorWrapper(async () => {
      return await C2U.Cloud.run("authyVerify", { code, newCellPhone });
    });
  }

  public async doesEmailExist(email: string) {
    return await this.$error.parseErrorWrapper(async () => {
      return await C2U.Cloud.run("doesEmailExist", { email });
    });
  }

  public async doesUserExist(username: string) {
    return await this.$error.parseErrorWrapper(async () => {
      return await C2U.Cloud.run("doesUserExist", { username });
    });
  }

  public async doesUserEmailExist(email: string) {
    return await this.$error.parseErrorWrapper(async () => {
      return await C2U.Cloud.run("doesUserEmailExist", { email });
    });
  }

  public async getAppVersionForceUpdate() {
    return await this.$error.parseErrorWrapper(async () => {
      return await C2U.Cloud.run("getAppVersionForceUpdate", {});
    });
  }

  public async loadPharmacyItems(params: C2U.Cloud.TLoadPharmacyItemsNew, postOnly?: boolean) {
    return await this.$error.parseErrorWrapper(async () => {
      const newParams = postOnly ? { ...params, isPostable: postOnly } : params;
      return await C2U.Cloud.run("loadPharmacyItemsNew", newParams);
    });
  }

  public async checkPromotion(code: string): Promise<PromotionCustomer | undefined> {
    return await this.$error.parseErrorWrapper(async () => {
      return await C2U.Cloud.run("checkPromotion", { code });
    });
  }

  public async createSquareCardToken(params: TCreateSquareCardTokenForCustomer): Promise<TCardResult | undefined> {
    return await this.$error.parseErrorWrapper(async () => {
      return await C2U.Cloud.run("createSquareCardTokenForCustomer", params);
    });
  }

  public async loadItemSingle(params: TLoadItemSingle): Promise<C2U.Cloud.TLoadItemSingleResult | undefined> {
    return await this.$error.parseErrorWrapper(async () => {
      return await C2U.Cloud.run("loadItemSingle", params);
    });
  }

  public async getErxData(code: string): Promise<C2U.Cloud.TgetERXFromURLResult> {
    return await this.$error.parseErrorWrapper(async () => {
      return await C2U.Cloud.run('getERXFromURL', { code });
    });
  }
  
  public async getNextAvailableShiftV2(pharmacyId: string): Promise<{ nextShift: TAllocatedShift, deliveryString: TConciergeDeliveryString }> {
    return await this.$error.parseErrorWrapper(async () => {
      return await C2U.Cloud.run('getNextAvailableShiftV2', { pharmacyId });
    });
  }

  public async googleGeocode(lat: string, lng: string) {
    return await this.$error.parseErrorWrapper(async () => {
      return await C2U.Cloud.run("googleGeocode", { lat, lng });
    });
  }

  public async updateCustomerMedicalProfile(params: TUpdateCustomerMedicalProfile) {
    return await this.$error.parseErrorWrapper(async () => {
      return await C2U.Cloud.run("updateCustomerMedicalProfile", params);
    });
  }

  public async userServiceability(pharmacyUserId: string, serviceable: boolean): Promise<TOrderFulfillmentMethod[]> {
    return await this.$error.parseErrorWrapper(async () => {
      return await Parse.Cloud.run('userServiceability', { pharmacyUserId, serviceable }) as TOrderFulfillmentMethod[];
    });
  }

  public async getPharmacyBracketModel(address: TSessionAddress): Promise<C2U.Cloud.TgetPharmacyBracketModelResult> {
    return await this.$error.parseErrorWrapper(async () => {
      console.error("[CloudFunctionsService]", "getPharmacyBracketModel", address);
      return await C2U.Cloud.run("getPharmacyBracketModel", { address });
    });
  }

  public async getShiftsForPharmacy(pharmacyId: string): Promise<{ simpleShifts: C2U.Interfaces.TAllocatedShift[] }> {
    return await this.$error.parseErrorWrapper(async () => {
      return await Parse.Cloud.run("getShiftsForPharmacy", { pharmacyId });
    });
  }

  public async getPharmacyAddress(pharmacyId: string): Promise<Partial<IPharmacy>> {
    return await this.$error.parseErrorWrapper(async () => {
      return await Parse.Cloud.run("getPharmacyAddress", { pharmacyId });
    });
  }

  public async markOrdersAsApprovedByCustomer(orderId: string): Promise<Order> {
    return await this.$error.parseErrorWrapper(async () => {
      return await Parse.Cloud.run("markOrdersAsApprovedByCustomer", { id: orderId });
    });
  }

  // This function retreives the delivery inconvenience message for the application from AppConfig
  public async getApplicationDeliveryInconvenienceMessage(): Promise<TBannerConfig> {
    return await this.$error.parseErrorWrapper(async () => {
      return await Parse.Cloud.run("getApplicationDeliveryInconvenienceMessage", {});
    });
  }

  // public async checkPharmacyHasItems(cartId: string, pharmacyObjectId: string): Promise<TCartItem[]> {
  //   return await this.$error.parseErrorWrapper(async () => {
  //     return await Parse.Cloud.run("checkPharmacyHasItems", { cartId, pharmacyObjectId });
  //   });
  // }

  public async requestClickAndCollectPin(orderId: string) {
    return await this.$error.parseErrorWrapper(async () => {
      return await Parse.Cloud.run("requestClickAndCollectPin", { orderId });
    });
  }

  public async markOrderAsCollected(orderId: string) {
    return await this.$error.parseErrorWrapper(async () => {
      return await Parse.Cloud.run("markOrderAsCollected", { id: orderId });
    });
  }
  
  public async getRegionalPricing(postcode: string): Promise<{regional: boolean, pricing: number}> {
    return await this.$error.parseErrorWrapper(async () => {
      return await Parse.Cloud.run("getRegionalPricing", { postcode });
    });
  }

  public async getOrderFulfillmentMethodsForPharmacy(address: TSessionAddress): Promise<TOrderFulfillmentMethod[]> {
    return await this.$error.parseErrorWrapper(async () => {
      // TODO - URGENT - This is inneficient, we should cache the pharmacyBracketModel
      const pharmacyBracketModel = await this.getPharmacyBracketModel(address);
      if (!pharmacyBracketModel?.id) return [];
      try {
        const orderFulfillmentMethods = await this.userServiceability(pharmacyBracketModel.id, pharmacyBracketModel.found);
        console.warn("[CloudFunctionsService]", "orderFulfillmentMethods", orderFulfillmentMethods);
        return orderFulfillmentMethods.sort((a, b) => {
          const order = {
            "OnDemand": 1,
            "Standard": 2,
            "clickAndCollect": 3,
            "Postal": 4,
            "PostalTemperatureControlled": 5,
          };
        
          const methodA = order[a.selectedMethod.method] || 4;
          const methodB = order[b.selectedMethod.method] || 4;
        
          return methodA - methodB;
        });
      } catch (error) {
        return [];
      }
    });
  }

  public async updateSubscriptionAddress(address: TSessionAddress): Promise<void> {
    return await this.$error.parseErrorWrapper(async () => {
      return await Parse.Cloud.run("updateSubscriptionAddress", { address });
    });
  }

  public async updateSubscriptionPayment(cardId: string): Promise<{ ordersUpdated: string[] }> {
    return await this.$error.parseErrorWrapper(async () => {
      return await Parse.Cloud.run("updateSubscriptionPayment", { cardId });
    });
  }
}
